import {
  faSquareFacebook,
  faSquareInstagram,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Stack} from "@mantine/core";
import classNames from "classnames";
import dayjs from "dayjs";
import Link from "next/link";

import classes from "./Footer.module.scss";

export interface FooterProps {
  className?: string | null;
}

export default function Footer({className}: FooterProps) {
  const socialIconColor = "gray";
  const socialIconSize = "2x";

  return (
    <div className={classNames(classes.footer, className)}>
      <div>
        <Stack gap="sm">
          <div className={classes.copyright}>
            Copyright &copy;{dayjs().format("YYYY")}{" "}
            <a href="https://modernovation.com" target="_blank">
              Modernovation, LLC
            </a>
          </div>
          <div className={classes.legal}>
            <Link href="/legal/terms">Terms of Service</Link>
            <div>&middot;</div>
            <Link href="/legal/terms">Privacy Policy</Link>
          </div>
          <div className={classes.socials}>
            <a
              title="Facebook"
              href="https://www.facebook.com/people/Camp-Observer/61558761065751/"
              target="_blank">
              <FontAwesomeIcon
                icon={faSquareFacebook}
                size={socialIconSize}
                color={socialIconColor}
              />
            </a>
            <a
              title="Instagram"
              href="https://www.instagram.com/campobserver/"
              target="_blank">
              <FontAwesomeIcon
                icon={faSquareInstagram}
                size={socialIconSize}
                color={socialIconColor}
              />
            </a>
            <a
              title="X/Twitter"
              href="https://x.com/campobserver"
              target="_blank">
              <FontAwesomeIcon
                icon={faSquareXTwitter}
                size={socialIconSize}
                color={socialIconColor}
              />
            </a>
          </div>
        </Stack>
      </div>
    </div>
  );
}
