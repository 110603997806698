import {useLazyQuery} from "@apollo/client";
import {
  faMagnifyingGlass,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Alert,
  CloseButton,
  Combobox,
  InputBase,
  Loader,
  MantineSize,
  ScrollArea,
  useCombobox,
} from "@mantine/core";
import classNames from "classnames";
import {debounce, groupBy, capitalize, noop} from "lodash";
import {useState, useEffect, useMemo} from "react";

import classes from "./PlacePicker.module.scss";
import * as queries from "../../graphql/queries";
import {Location, SearchResult} from "../../graphql/types";
import {getLogger} from "../../lib/logging";

const log = getLogger(__filename);

export interface PlacePickerProps {
  size?: MantineSize; // Size of the input element
  placeholder?: string; // Input element text placeholder
  location?: Location | null; // User's current location (sorts results with nearest taking priority)
  autoFocus?: boolean; // Automatically focus on this input element?
  className?: string;
  onSelection?(result: SearchResult): void; // Callback to invoke when selection is made
  onBlur?(): void; // Callback to invoke when the input element loses focus
}

/**
 * Autocomplete dropdown picker for any place that can be displayed on the map
 * (e.g. neighborhood, point of interest, park)
 *
 * Uses the Mapbox Places API under the hood.
 */
export default function PlacePicker({
  className,
  size = "sm",
  placeholder = "Search...",
  location = null,
  autoFocus = false,
  onSelection = noop,
  onBlur = noop,
}: PlacePickerProps) {
  const [search, setSearch] = useState<string>("");
  const [value, setValue] = useState<string | null>(null);
  const [
    textSearch,
    {
      loading: searchLoading,
      error: searchError,
      data: {search: {textSearch: searchResult = undefined} = {}} = {},
    },
  ] = useLazyQuery(queries.TEXT_SEARCH);
  const [getTextSearchResultLocation, _getTextSearchResultLocation] =
    useLazyQuery(queries.TEXT_SEARCH_RESULT_LOCATION);

  const debouncedTextSearch = useMemo(() => {
    return debounce(term => {
      if (!term) {
        return;
      }

      log.info(`Searching for place: ${term}`);
      return textSearch({
        variables: {
          term,
          near:
            location?.latitude && location?.longitude
              ? {
                  latitude: location.latitude || 0,
                  longitude: location.longitude || 0,
                }
              : undefined,
          filter: {
            onlyTypes: ["Place"],
          },
        },
      });
    }, 600);
  }, [location]);

  useEffect(() => {
    debouncedTextSearch(search);
  }, [search]);

  const comboBox = useCombobox({
    onDropdownClose: () => {
      comboBox.resetSelectedOption();
    },
  });

  const results: SearchResult[] | void = searchResult?.results;
  const resultsByTypeAndId: {[typeAndId: string]: SearchResult} = {};
  for (const result of results || []) {
    resultsByTypeAndId[`${result.type}:${result.id}`] = result;
  }

  useEffect(() => {
    if (results) {
      comboBox.selectFirstOption();
    }
  }, [searchResult]);

  let options;
  if (searchLoading) {
    options = <Combobox.Empty>Searching...</Combobox.Empty>;
  } else if (searchError) {
    options = (
      <Combobox.Empty>
        <Alert
          variant="filled"
          color="red"
          icon={<FontAwesomeIcon icon={faExclamationTriangle} beatFade />}>
          {searchError.message}
        </Alert>
      </Combobox.Empty>
    );
  } else if (results && results.length > 0) {
    const grouped = groupBy(results, (result: SearchResult) => result.type);
    options = Object.keys(grouped).map(type => {
      const groupLabel = type ? capitalize(type) : "Location";

      return (
        <Combobox.Group label={groupLabel} key={type}>
          {grouped[type].map((result: SearchResult) => {
            const resultKey = `${result.type}:${result.id}`;
            const regionLabel: string | null | undefined = result.context;
            return (
              <Combobox.Option value={resultKey} key={resultKey}>
                <div className={classes.placeName}>{result.name}</div>
                {regionLabel && (
                  <div className={classes.placeRegion}>{regionLabel}</div>
                )}
              </Combobox.Option>
            );
          })}
        </Combobox.Group>
      );
    });
  } else if (results && results.length === 0) {
    options = <Combobox.Empty>No results found</Combobox.Empty>;
  } else {
    options = null;
  }

  const leftSection = <FontAwesomeIcon icon={faMagnifyingGlass} />;

  let rightSection;
  if (searchLoading) {
    rightSection = <Loader size="sm" />;
  } else if (value) {
    rightSection = (
      <CloseButton
        size={size}
        onMouseDown={event => event.preventDefault()}
        onClick={() => {
          setValue("");
          setSearch("");
        }}
        aria-label="Clear value"
      />
    );
  }

  return (
    <Combobox
      size={size}
      store={comboBox}
      withinPortal={false}
      onOptionSubmit={async typeAndId => {
        const result = resultsByTypeAndId[typeAndId];
        if (!result) {
          log.error(`Result not cached for ${typeAndId}`);
          return;
        }

        comboBox.closeDropdown();

        // Select result
        setValue(typeAndId);
        setSearch(result.name || "");

        if (result.location) {
          // Result already has location
          onSelection(result);
        } else {
          // Lookup result location
          const {
            error: resultLocationError,
            data: {
              search: {
                textSearchResultLocation: resultLocation = undefined,
              } = {},
            } = {},
          } = await getTextSearchResultLocation({
            variables: {
              mapableType: result.type,
              mapableId: result.id,
              correlationKey: searchResult?.correlationKey,
            },
          });

          if (resultLocationError) {
            alert(
              `Error selecting result: ${JSON.stringify(resultLocationError)}`
            );
            return;
          }

          const resultClone = structuredClone(result);
          resultClone.location = resultLocation;
          onSelection(resultClone);
        }
      }}>
      <Combobox.Target>
        <InputBase
          className={classNames(classes.input, className)}
          size={size}
          value={search}
          autoFocus={autoFocus}
          onChange={event => {
            comboBox.openDropdown();
            comboBox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => comboBox.openDropdown()}
          onFocus={() => comboBox.openDropdown()}
          onBlur={() => {
            comboBox.closeDropdown();
            onBlur();
          }}
          leftSection={leftSection}
          rightSection={rightSection}
          placeholder={placeholder}
        />
      </Combobox.Target>
      {options && (
        <Combobox.Dropdown>
          <Combobox.Options>
            <ScrollArea.Autosize type="scroll" mah={300}>
              {options}
            </ScrollArea.Autosize>
          </Combobox.Options>
        </Combobox.Dropdown>
      )}
    </Combobox>
  );
}
