import {compact, isNil} from "lodash";

import type {SearchState} from "./types";
import {omitNil} from "./utils";
import type {MapSearchInput} from "../graphql/types";

const LIST_DELIM = "~";

export const DEFAULT_SEARCH_INPUT: Readonly<MapSearchInput> = Object.freeze({
  filter: {
    dates: {
      min: null,
      max: null,
    },
    excludeFCFS: false,
    excludeGroup: false,
    excludeMandatoryEquipment: false,
    excludeUnreleased: false,
    isStrict: false,
    maxVehicleLengthFeet: null,
    nights: null,
    onlyAccessible: false,
    onlyFiresAllowed: false,
    onlyPetsAllowed: false,
    onlyFirePit: false,
    onlyPicnicTable: false,
    onlyWaterHookup: false,
    onlySewerHookup: false,
    people: {
      min: null,
      max: null,
    },
    vehicles: null,
  },
  onlyMatches: false,
  zoom: null,
});

export const DEFAULT_SEARCH_STATE: Readonly<SearchState> = Object.freeze({
  searchInput: DEFAULT_SEARCH_INPUT,
  results: [],
  mapCenter: null,
  ownCenter: null,
  mapRect: null,
  layers: [],
  showFilters: false,
  showLayers: false,
  showResultsStrip: false,
  showSearchLoader: false,
  runTutorial: false,
  hover: null,
  selection: null,
  version: 0,
});

/**
 * Deserialize query string to SearchState
 *
 * @param queryString URL query string to deserialize
 * @return Deserialized SearchState
 */
export function queryStringToSearchState(queryString: string): SearchState {
  const query = new URLSearchParams(queryString);

  const flattened: Record<string, any> = {};
  for (const [key, value] of query.entries()) {
    flattened[key] = value;
  }

  const show: string[] = compact((flattened.sh || "").split(LIST_DELIM));
  const layers: string[] = compact((flattened.ly || "").split(LIST_DELIM));
  const centerLatLon = strToFloatRange(flattened.c);

  const state: SearchState = omitNil({
    ...DEFAULT_SEARCH_STATE,
    mapCenter: centerLatLon
      ? omitNil({
          latitude: centerLatLon.min,
          longitude: centerLatLon.max,
        })
      : null,
    layers,
    showFilters: show.includes("f"),
    showLayers: show.includes("ly"),
    showResultsStrip: show.includes("rs"),
    searchInput: omitNil({
      zoom: strToFloat(flattened.z),
      onlyMatches: strToBool(flattened.om),
      pagination: omitNil({
        page: strToInt(flattened.p),
        pageSize: strToInt(flattened.ps),
      }),
      sort: omitNil({
        field: flattened.sf,
        direction: flattened.sd,
      }),
      filter: omitNil({
        dates: strToStrRange(flattened["f.d"]),
        excludeFCFS: strToBool(flattened["f.eff"]),
        excludeGroup: strToBool(flattened["f.egr"]),
        excludeMandatoryEquipment: strToBool(flattened["f.eme"]),
        excludeUnreleased: strToBool(flattened["f.eur"]),
        isStrict: strToBool(flattened["f.s"]),
        maxVehicleLengthFeet: strToInt(flattened["f.mvl"]),
        nights: strToInt(flattened["f.n"]),
        onlyGroup: strToBool(flattened["f.ogr"]),
        onlyAccessible: strToBool(flattened["f.oac"]),
        onlyFiresAllowed: strToBool(flattened["f.ofa"]),
        onlyPetsAllowed: strToBool(flattened["f.opa"]),
        onlyFirePit: strToBool(flattened["f.ofp"]),
        onlyPicnicTable: strToBool(flattened["f.opt"]),
        onlyWaterHookup: strToBool(flattened["f.owh"]),
        onlySewerHookup: strToBool(flattened["f.osh"]),
        vehicles: strToInt(flattened["f.veh"]),
        drivewayLengthFeet: strToInt(flattened["f.drl"]),
        electricityHookupAmps: strToIntRange(flattened["f.eha"]),
        people: strToIntRange(flattened["f.p"]),
      }),
    }),
  });

  // console.log("Search state from URL", state);
  return state;
}

/**
 * Serialize SearchState to query string
 *
 * @param state SearchState to serialize
 * @return Serialized query string
 */
export function searchStateToQueryString(state: Partial<SearchState>): string {
  const searchInput = state.searchInput;
  const filter = searchInput?.filter;

  const flattened: Record<string, any> = omitNil({
    // SearchState
    c: state.mapCenter
      ? `${state.mapCenter.latitude}..${state.mapCenter.longitude}`
      : null,
    ly: strToStr(compact(state.layers || []).join(LIST_DELIM)),
    sh: strToStr(
      compact([
        state.showFilters ? "f" : null,
        state.showLayers ? "ly" : null,
        state.showResultsStrip ? "rs" : null,
      ]).join(LIST_DELIM)
    ),
    // SearchInput
    z: searchInput?.zoom,
    om: boolToStr(searchInput?.onlyMatches),
    p: searchInput?.pagination?.page,
    ps: searchInput?.pagination?.pageSize,
    sf: searchInput?.sort?.field,
    sd: searchInput?.sort?.direction,
    // Filters
    "f.d":
      filter?.dates?.min || filter?.dates?.max
        ? `${filter.dates.min || ""}..${filter.dates.max || ""}`
        : null,
    "f.eff": boolToStr(filter?.excludeFCFS),
    "f.egr": boolToStr(filter?.excludeGroup),
    "f.eme": boolToStr(filter?.excludeMandatoryEquipment),
    "f.eur": boolToStr(filter?.excludeUnreleased),
    "f.s": boolToStr(filter?.isStrict),
    "f.mvl": filter?.maxVehicleLengthFeet,
    "f.n": filter?.nights,
    "f.ogr": boolToStr(filter?.onlyGroup),
    "f.oac": boolToStr(filter?.onlyAccessible),
    "f.ofa": boolToStr(filter?.onlyFiresAllowed),
    "f.opa": boolToStr(filter?.onlyPetsAllowed),
    "f.ofp": boolToStr(filter?.onlyFirePit),
    "f.opt": boolToStr(filter?.onlyPicnicTable),
    "f.owh": boolToStr(filter?.onlyWaterHookup),
    "f.osh": boolToStr(filter?.onlySewerHookup),
    "f.veh": filter?.vehicles,
    "f.drl": filter?.drivewayLengthFeet,
    "f.eha":
      filter?.electricityHookupAmps?.min || filter?.electricityHookupAmps?.max
        ? `${filter.electricityHookupAmps.min || ""}..${filter.electricityHookupAmps.max || ""}`
        : null,
    "f.p":
      filter?.people?.min || filter?.people?.max
        ? `${filter.people.min || ""}..${filter.people.max || ""}`
        : null,
    "f.v": filter?.vehicles,
  });

  const query = new URLSearchParams(flattened);
  return query.toString();
}

function boolToStr(v: boolean | null | undefined): string | null {
  return v ? "y" : null;
}

function strToBool(v: string | null | undefined): boolean | null {
  if (isNil(v)) {
    return null;
  }

  return v === "y";
}

function strToInt(v: string | null | undefined): number | null {
  if (isNil(v)) {
    return null;
  }

  return parseInt(v, 10);
}

function strToFloat(v: string | null | undefined): number | null {
  if (isNil(v)) {
    return null;
  }

  return parseFloat(v);
}

function strToStr(v: string | null | undefined): string | null {
  return v ? v : null;
}

function strToStrRange(
  v: string | null | undefined
): {min: string | null; max: string | null} | null {
  if (isNil(v)) {
    return null;
  }

  const parts: string[] = v.split("..", 2);
  return {
    min: strToStr(parts.length > 0 ? parts[0] : null),
    max: strToStr(parts.length > 1 ? parts[1] : null),
  };
}

function strToIntRange(
  v: string | null | undefined
): {min: number | null; max: number | null} | null {
  const range = strToStrRange(v);
  if (!range) {
    return null;
  }

  return {
    min: strToInt(range.min),
    max: strToInt(range.max),
  };
}

function strToFloatRange(
  v: string | null | undefined
): {min: number | null; max: number | null} | null {
  const range = strToStrRange(v);
  if (!range) {
    return null;
  }

  return {
    min: strToFloat(range.min),
    max: strToFloat(range.max),
  };
}
